
import { Options, Vue } from 'vue-class-component';
import { clientModule } from '@/modules/client/client.vuex-module';
import { ClientLocation } from '@/common/models/ClientLocation.model';
import { PackageViewMode } from '@/common/enums/PackageViewMode.enum';
import { Item } from '@/common/models/Item.model';
import { Prop, ProvideReactive } from 'vue-property-decorator';
import { orderModule } from '@/modules/order/orderModule.vuex-module';

@Options({
	name: 'PortalView',
	components: {},
})
export default class PortalView extends Vue {
	@Prop({ default: '0' }) clientLocationKey!: string;

	public PackageViewMode = PackageViewMode;

	get viewMode(): PackageViewMode {
		return orderModule.catalogView;
	}

	get isPackage(): boolean {
		return this.viewMode == PackageViewMode.PACKAGE;
	}
	get logourl(): string | null {
		if (!this.location) {
			return null;
		}
		if (!this.location.PhotoDomain || !this.location.PhotoFolder || !this.location.LightLogoVersion) {
			return null;
		}
		return this.location.PhotoDomain + 'h_140' + '/v' + this.location.LightLogoVersion + this.location.PhotoFolder + '/darklogo';
	}
	get items(): Item[] {
		if (!this.location) {
			return [];
		}
		//currently not supporting duration based, so filter out activities and packages that contain them
		switch (this.viewMode) {
			case PackageViewMode.INDIVIDUAL:
				return this.location.Individual.filter(this.validateItem);
			case PackageViewMode.PACKAGE:
				return this.location.Packages.filter(this.validateItem);
			default:
				return this.location.Individual.filter(this.validateItem);
		}
	}
	validateItem(item: Item): boolean {
		if (item.HideFromCustomer) return false;
		const validCapacityTypes = [1];
		const validAppointmentTypes = [1, 2];
		const validPackageTypes = [1, 0];
		return item.Activities.every((a) => validCapacityTypes.includes(a.CapacityTypeKey) && validAppointmentTypes.includes(a.AppointmentTypeKey)) && validPackageTypes.includes(item.PackageTypeKey);
	}
	get location(): ClientLocation | null {
		return clientModule.location;
	}
	get loading(): boolean {
		return clientModule._loading;
	}
	navTo(item: Item): void {
		orderModule.setOpenItem(item);
		this.$router.push({ path: `/portal/${this.clientLocationKey}/${this.viewMode}/${item.PackageKey ?? item.EntityHierarchyKey}` });
	}
	getFromString(item: Item): string {
		var rs: string[] = [];
		if (item.PackageKey || item.OverrideAutoPrice) {
			if (item.DisplayPrice) {
				rs.push(item.DisplayPrice);
			}
			if (item.DisplayDiscount) {
				rs.push(item.DisplayDiscount);
			}
		} else {
			rs.push('From ' + item.FromPrice + ' / ' + item.FromPriceName);
		}
		return rs.join('<br>');
	}
	getOptions(item: Item): string[] {
		var res: string[] = [];
		item.Activities.forEach((entity) => {
			if (item.PackageKey) {
				res.push(entity.Name);
			}
			if (entity.Difficulty) {
				res.push(`*${entity.Difficulty}`);
			}
			if (entity.Duration) {
				res.push(`*${entity.Duration}`);
			}
		});
		return res;
	}
	getImage(item: Item): string {
		return item.PhotoDomain + 'w_410,h_300,c_fill' + '/v' + item.PhotoVersion + item.PhotoFolder + '/original';
	}
	setView(mode: PackageViewMode): void {
		orderModule.updateCatalogView(mode);
	}
	created(): void {
		clientModule.updateClientLocation(parseInt(this.clientLocationKey));
	}
	home() {
		let wk = (window as any).webkit;
		if (wk && wk.messageHandlers && wk.messageHandlers.onClose) {
			wk.messageHandlers.onClose.postMessage({
				message: 'Hey foo',
			});
		}
	}
}
